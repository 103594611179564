import React, { useState, useMemo, useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import {
  setSenderEmail,
  setSenderFullName,
  setAreYouSigner,
  setSigners,
  setOrdered,
} from '../../redux/createTemplate/actions';

import {
  useSigners,
  getNotEmptyUniqueSigners,
  getSignersValidationStatus,
} from './helpers';

import SigningLimitReachedModal from '../../components/Modals/SigningLimitReachedModal';
import NotificationManager from '../../components/Notifications/NotificationManager';
import GlobalLoader from '../GlobalLoader';
import SignersFormV2 from '../SignerFormV2';
import { v4 as uuidV4 } from 'uuid';
import Switch from '../custom/Switch';
import { SIGNERS_LIST_TYPE } from './constants';
import { RECIPIENTS_TYPE } from '../../static';
import Checkbox from '../custom/Checkbox';
import { setWhoNeedsToSign } from '../../redux/createTemplate/actions';

const RECIPIENT_DEFAULT = {
  email: '',
  fullName: '',
  isEmailValid: false,
  isNameValid: false,
  isEmailTouched: false,
  isNameTouched: false,
  isMe: false,
  isContactNameTouched: false,
  type: RECIPIENTS_TYPE.SIGNER,
  isDisabled: false,
};

const AddRecipientsStep = props => {
  let initialSigners = [];
  const [
    showSigningLimitReachedModal,
    setShowSigningLimitReachedModal,
  ] = useState(false);
  const [showLoader, setShowLoader] = useState(false);
  const colors = useRef(['#89bfff', '#4aefba', '#e9927b']);
  const [showError, setShowError] = useState(false);
  const [showErrorToggle, setShowErrorToggle] = useState(false);
  const whoNeedsToSign = props.whoNeedsToSign || SIGNERS_LIST_TYPE.ONLY_ME;
  // if (props.signers.length > 0) {
  //   initialSigners = props.signers;
  // }

  if (
    [SIGNERS_LIST_TYPE.ONLY_ME, SIGNERS_LIST_TYPE.ME_OTHERS].includes(
      whoNeedsToSign,
    )
  ) {
    if (props.user) {
      initialSigners.unshift({
        email: props.user.email,
        fullName: props.user.fullName,
        role: uuidV4(),
        isEmailValid: true,
        isNameValid: true,
        isChanged: false,
        isDisabled: true,
        isMe: true,
        type: RECIPIENTS_TYPE.SIGNER,
      });
    } else {
      initialSigners.push({
        role: uuidV4(),
        ...RECIPIENT_DEFAULT,
        isMe: true,
      });
    }
  }
  if (
    [SIGNERS_LIST_TYPE.ME_OTHERS, SIGNERS_LIST_TYPE.OTHERS].includes(
      whoNeedsToSign,
    )
  ) {
    initialSigners.push({
      role: uuidV4(),
      ...RECIPIENT_DEFAULT,
    });
  }

  const {
    signers,
    addSigner,
    updateSigners,
    removeSigner,
    setSignerFullName,
    setSignerEmail,
    setFullNameEmail,
  } = useSigners(initialSigners);

  const amISigner = useMemo(
    () => signers.findIndex(signer => !!signer?.isMe) > -1,
    [signers],
  );

  // const disableNext = useMemo(() => {
  //   const {
  //     hasInvalidSigner,
  //     notEmptySignersAmount,
  //   } = getSignersValidationStatus(signers);
  //   return notEmptySignersAmount < signers.length || hasInvalidSigner;
  // }, [signers]);

  const toggleMeSigner = e => {
    let cloneSigners = [...signers];
    if (e.target.checked) {
      if (props.user) {
        cloneSigners.unshift({
          email: props.user.email,
          fullName: props.user.fullName,
          role: uuidV4(),
          isEmailValid: true,
          isNameValid: true,
          isChanged: false,
          isDisabled: true,
          isMe: true,
        });
      } else {
        cloneSigners.unshift({
          role: uuidV4(),
          ...RECIPIENT_DEFAULT,
          isMe: true,
        });
      }
    } else {
      cloneSigners = cloneSigners.filter(signer => !signer?.isMe);
      if (cloneSigners.length === 0) {
        // Prevent empty list
        cloneSigners.push({
          role: uuidV4(),
          ...RECIPIENT_DEFAULT,
          isMe: false,
        });
      }
    }
    updateSigners(cloneSigners);
  };

  const [allowFormValidation, setAllowFormValidation] = useState(false);

  function goToNextStep() {
    setShowError(true);
    if (signers.length > 0) {
      const {
        hasInvalidSigner,
        notEmptySignersAmount,
      } = getSignersValidationStatus(signers);

      if (notEmptySignersAmount === signers.length && !hasInvalidSigner) {
        const signersList = signers.map((signer, idx) => ({
          ...signer,
          color: colors.current[idx],
        }));
        // const nonDuplicateList = getNotEmptyUniqueSigners(signersList);
        props.setSigners(signersList);
        props.goToNextStepEmitter(signersList);
      }
    }
  }

  const setColors = col => {
    colors.current = col;
  };

  useEffect(() => {
    if (props.activeSignerContact) {
      updateSigners(props.signers);
    }
  }, [props.activeSignerContact, props.signers]);

  return (
    <>
      {showLoader && <GlobalLoader />}

      <div className={`esign-modal signing-step-modal ${props.animationState}`}>
        <div className={'signing-step-modal__header'}>
          <h3 className="esign-modal__title">Add recipients</h3>
          {props.requestType !== 'notarize' && (
            <div className={'signing-step-modal__order-switch'}>
              <Switch
                checked={props.ordered}
                onChange={e => {
                  const emails = signers
                    .map(item => item.email)
                    .filter(email => email !== '');
                  const duplicates = emails.filter(
                    (email, index, self) => self.indexOf(email) !== index,
                  );
                  const hasDuplicates = duplicates.length > 0;
                  if (hasDuplicates) {
                    setShowErrorToggle(true);
                    props.setOrdered(true);
                    setTimeout(() => {
                      setShowErrorToggle(false);
                    }, 5000);
                    return;
                  }
                  props.setOrdered(e.target.checked);
                }}
              />{' '}
              Set signing order
            </div>
          )}
        </div>
        <SignersFormV2
          isNotarizing={props.requestType === 'notarize'}
          signingOrder={props.ordered}
          setOrdered={props.setOrdered}
          signers={signers}
          addSigner={addSigner}
          setSigners={updateSigners}
          removeSigner={removeSigner}
          onFullNameChange={setSignerFullName}
          onEmailChange={setSignerEmail}
          onFullNameEmailChange={setFullNameEmail}
          onEnterKeyPress={goToNextStep}
          showErrorMessage={allowFormValidation}
          colors={colors}
          setColors={setColors}
          showError={showError}
          showErrorToggle={showErrorToggle}
        />
        <div className="esign-modal__footer signing-step-modal__footer">
          {(props.requestType === 'notarize' || props?.enableMeSigner) && (
            <div className={'signing-step-modal__footer--left'}>
              <Checkbox
                checked={amISigner}
                onChange={toggleMeSigner}
                label={'I’m a signer'}
              />
            </div>
          )}
          {props?.enableBack && (
            <button
              className="esign-button esign-button--light"
              onClick={() => {
                props.setAreYouSigner(true);
                props.goToPrevStepEmitter();
              }}
            >
              Back
            </button>
          )}
          <button
            className="esign-button esign-button--blue"
            onClick={goToNextStep}
            // disabled={disableNext}
          >
            Next
          </button>
        </div>
      </div>

      {showSigningLimitReachedModal && (
        <SigningLimitReachedModal
          emitCloseModal={() => setShowSigningLimitReachedModal(false)}
        />
      )}
    </>
  );
};

AddRecipientsStep.propTypes = {
  closeModalEmitter: PropTypes.func.isRequired,
  goToNextStepEmitter: PropTypes.func.isRequired,
  goToPrevStepEmitter: PropTypes.func.isRequired,
};

const mapStateToProps = ({ authUser, createTemplate }) => {
  const { user } = authUser;

  const {
    requestType,
    signers,
    areYouSigner,
    senderEmail,
    receiveCopy,
    whoNeedsToSign,
    ordered,
    activeSignerContact,
  } = createTemplate;

  return {
    user,
    requestType,
    signers,
    areYouSigner,
    senderEmail,
    receiveCopy,
    whoNeedsToSign,
    ordered,
    activeSignerContact,
  };
};

const mapDispatchToProps = {
  setSenderEmail,
  setSenderFullName,
  setAreYouSigner,
  setSigners,
  setOrdered,
  setWhoNeedsToSign,
};

export default connect(mapStateToProps, mapDispatchToProps)(AddRecipientsStep);
