import { api, getTokenFromCookie } from './index';
import { BASE_URL_V1, BASE_URL_V2 } from '../static/config';
import axios from 'axios';

// export const auth = (username, password) => api.post({
//   path: '/token-auth/',
//   body: { username, password }
// });

export const registerUser = registerData =>
  api.post({
    path: '/user',
    body: registerData,
  });

export const registerUserGoogle = idToken =>
  api.post({
    path: '/user/google',
    body: { idToken },
  });

export const registerUserFacebook = accessToken =>
  api.post({
    path: '/user/facebook',
    body: { accessToken },
  });

export const registerUserApple = data =>
  api.post({
    path: '/user/ios/web/authenticate',
    body: data,
  });

export const loginUser = (email, password) =>
  api.post({
    path: '/user/login',
    body: { email, password },
  });

export const loginUserV2 = payload =>
  api.post({
    path: '/user/login',
    body: payload,
    serverUrl: BASE_URL_V2,
  });

export const loginUserGoogle = idToken =>
  api.post({
    // path: '/user/login/google',
    path: '/user/authenticate/google',
    body: { idToken },
  });

export const loginUserFacebook = accessToken =>
  api.post({
    // path: '/user/login/facebook',
    path: '/user/authenticate/facebook',
    body: { accessToken },
  });

export const loginUserApple = data =>
  api.post({
    path: '/user/ios/web/authenticate',
    body: data,
  });

export const loginUserByToken = data =>
  api.post({
    path: '/user/login/token',
    body: data,
  });

export const prologToken = () =>
  api.post({
    path: '/user/session/prolong',
  });

export const completeProfile = ({ email, password, fullName, completeToken }) =>
  api.post({
    path: '/user/complete',
    body: { email, password, fullName, completeToken },
  });

export const logoutFromUser = () =>
  api.post({
    path: '/user/logout',
    body: { refreshToken: `Bearer ${getTokenFromCookie()}` },
  });

export const logoutFromAllDevices = () =>
  api.post({
    path: '/user/logout/all',
  });

export const getAllActiveSessions = () =>
  api.get({
    path: '/user/session',
  });

export const getUserInfo = () =>
  api.get({
    path: '/user/me',
  });

export const uploadUserAvatar = file =>
  axios
    .post(`${BASE_URL_V1}/user/logo`, file, {
      headers: {
        'Content-Type': `multipart/form-data; boundary=---------------------------${new Date().getTime()}`,
        Authorization: `Bearer ${getTokenFromCookie()}`,
      },
    })
    .then(res => res)
    .catch(err => err);

export const getUserAvatar = () =>
  api.get({
    path: '/user/logo/url',
  });

export const deleteUserAvatar = () =>
  api.delete({
    path: '/user/logo',
  });

export const resetPassword = body =>
  api.post({
    path: '/user/password-reset/init',
    body,
  });

export const setNewPassword = body =>
  api.post({
    path: '/user/password-reset/complete',
    body,
  });

export const resetPasswordWithOTP = body =>
  api.post({
    path: '/user/password-reset/otp/init',
    body,
  });

export const setNewPasswordWithOtp = body =>
  api.post({
    path: '/user/password-reset/otp/complete',
    body,
  });

export const deleteUser = () =>
  api.delete({
    path: '/user',
  });

export const setUserSettings = (twoFactor, notificationsEnabled, fullName) =>
  api.put({
    path: '/user/settings',
    body: { twoFactor, notificationsEnabled, fullName },
  });

export const updateUserFullName = fullName =>
  api.post({
    path: '/user/settings/name',
    body: { fullName },
  });

export const updateUserAddressPhone = (address, phone) =>
  api.post({
    path: '/user/settings/personal',
    body: { address, phone },
  });

export const get2FAQRCode = () => {
  return fetch(`${BASE_URL_V1}/user/2fa/totp/generate`, {
    method: 'get',
    headers: new Headers({
      Authorization: `Bearer ${getTokenFromCookie()}`,
    }),
  });
};

export const enable2FA = () =>
  api.post({
    path: '/user/2fa/enable',
    body: {},
  });

export const disable2FA = () =>
  api.post({
    path: '/user/2fa/disable',
    body: {},
  });

export const get2FASettings = () =>
  api.get({
    path: '/user/2fa',
  });

/**
 * @param {('iphone'|'android')} device
 * @param {string} verificationCode
 */
export const enable2FAViaAuthenticatorApp = (device, verificationCode) =>
  api.post({
    path: '/user/2fa/totp/enable',
    body: { device, verificationCode },
  });

export const disable2FAViaAuthenticatorApp = () =>
  api.post({
    path: '/user/2fa/totp/disable',
  });

/**
 * @param {string} otpToken
 */
export const loginViaAuthenticatorCode = otpToken =>
  api.post({
    path: '/user/2fa/totp/login',
    body: { otpToken },
  });

export const enable2FAViaEmail = () =>
  api.post({
    path: '/user/2fa/email/enable',
    body: {},
  });

export const disable2FAViaEmail = () =>
  api.post({
    path: '/user/2fa/email/disable',
    body: {},
  });

export const request2FAEmailCode = () =>
  api.post({
    path: '/user/2fa/email/request',
    body: {},
  });

/**
 * @param {string} emailCode
 */
export const loginViaEmailCode = emailCode =>
  api.post({
    path: '/user/2fa/email/login',
    body: { emailCode },
  });

export const userActiveResend = () =>
  api.post({
    path: '/user/activate/resend',
  });

export const reVerifyEmail = email =>
  api.post({
    path: '/user/settings/email/not-verified',
    body: { email },
  });

export const verifyHuman = async token =>
  api.post({
    path: '/user/recaptcha/verify',
    body: { token },
  });

export const updateMainSetting = settingsPayload =>
  api.put({
    path: '/user/settings/main',
    body: settingsPayload,
  });

export const updateUserNotification = (
  emailOnRecipientView,
  emailOnRecipientSignature,
  emailOnCompletedSignature,
  emailCopyOfInvoice,
) =>
  api.put({
    path: '/user/settings/notification',
    body: {
      emailOnRecipientView,
      emailOnRecipientSignature,
      emailOnCompletedSignature,
      emailCopyOfInvoice,
    },
  });

export const updateUserSignatureNotification = guideOptionalFields =>
  api.put({
    path: '/user/settings/signature',
    body: { guideOptionalFields },
  });

export const updateUserContatctNotification = (
  autoFillContacts,
  autoSaveRecipients,
) =>
  api.put({
    path: '/user/settings/contact',
    body: { autoFillContacts, autoSaveRecipients },
  });

export const updateAutoBillingSetting = autoRebilling =>
  api.put({
    path: '/user/settings/auto-rebilling',
    body: { autoRebilling },
  });

export const updateNoCompleteUserEmail = body =>
  api.post({
    path: '/user/settings/email/public',
    body,
  });
