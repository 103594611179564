import { ELEMENTS_PER_PAGE } from './config';

export const animationStateEnumeration = {
  STATIC: 'STATIC',
  RIGHT_TO_LEFT: 'RIGHT_TO_LEFT',
  LEFT_TO_RIGHT: 'LEFT_TO_RIGHT',
};

export const MyTemplatesFilters = [
  { value: '', label: 'None' },
  { value: 'standard-templates', label: 'Standard Templates' },
  { value: 'links', label: 'Links' },
];

export const MyDocumentsFilters = [
  { value: '', label: 'None' },
  { value: 'actionRequired', label: 'Action required' },
  { value: 'waitForOthers', label: 'Waiting for others' },
  { value: 'expiring', label: 'Expiring soon' },
  { value: 'unsigned', label: 'Unsigned' },
  { value: 'signed', label: 'Signed' },
  { value: 'notarized', label: 'Notarized' },
];

export const InboxFilters = [
  { value: '', label: 'None' },
  { value: 'actionRequired', label: 'Action required' },
  { value: 'waitForOthers', label: 'Waiting for others' },
  { value: 'expiring', label: 'Expiring soon' },
  { value: 'unsigned', label: 'Unsigned' },
  { value: 'signed', label: 'Signed' },
  { value: 'notarized', label: 'Notarized' },
];

export const MyDocumentsTabFilters = [
  { value: '', label: 'None' },
  { value: 'actionRequired', label: 'Action required' },
  { value: 'waitForOthers', label: 'Waiting for others' },
  { value: 'signed', label: 'Completed' },
  { value: 'unsigned', label: 'Unsigned' },
  { value: 'voided', label: 'Voided' },
];

export const MyTemplatesTabFilters = [
  { value: '', label: 'None' },
  { value: 'standard-templates', label: 'Standard Templates' },
  { value: 'links', label: 'Links' },
];

export const TrashedFilters = [
  { value: '', label: 'None' },
  { value: 'deletingSoon', label: 'Deleting Soon' },
  { value: 'notarized', label: 'Notarized' },
  { value: 'signed', label: 'Signed' },
];

export const SharedWithMeFilters = [
  { value: '', label: 'None' },
  { value: 'folder', label: 'Folders' },
  { value: 'file', label: 'Files' },
];

export const OtherPagesFilters = [
  { value: '', label: 'None' },
  { value: 'notarized', label: 'Notarized' },
  { value: 'signed', label: 'Signed' },
];

export const elementPerPageOptions = [
  { value: ELEMENTS_PER_PAGE, label: ELEMENTS_PER_PAGE },
  { value: 25, label: 25 },
  { value: 50, label: 50 },
  { value: 100, label: 100 },
];

export const avatarBackgroundColor = [
  '#D3E3FF',
  '#FFD1B3',
  '#DDFFE3',
  '#F7D7FF',
  '#BAE7FF',
  '#8FE3FF',
  '#E38FFF',
];

export const PAGE_NAMES = {
  trash: 'Trash',
  starred: 'Starred',
  shared: 'Shared',
  documents: 'Documents',
  sent: 'Sent',
  inbox: 'Inbox',
  templates: 'Templates',
};

export const RECIPIENTS_TYPE = {
  SIGNER: 'signer',
  CC: 'cc',
};

export const RECIPIENTS_TYPE_LABEL = {
  signer: 'Signer',
  cc: 'CC',
};

export const recipientsExample = [
  { value: 'Kevin Stuart', label: 'Kevin Stuart', role: 'tenant' },
  { value: 'Leonardo Da Vinci', label: 'Leonardo Da Vinci', role: 'tenant' },
  { value: 'Danny Bolton', label: 'Danny Bolton', role: 'tenant' },
];

export const TemplateDestinationTab = [
  { value: 'myFolders', label: 'My Folders' },
  // { value: 'teams',     label: 'Teams' },
];

export const DocumentTabModal = [
  { value: 'documents', label: 'Documents' },
  // { value: 'templates', label: 'Templates' },
];

export const PLAN_FREQUENCY = {
  ANNUALLY: 'annually',
  MONTHLY: 'monthly',
};

export const PLAN_NAME = {
  FREE: 'free',
  PREMIUM: 'premium',
  SINGLE_DOCUMENT: 'single_document',
};

export const BILLING_STATUS = {
  ACTIVE: 'active',
  STOPPED: 'stopped',
};

export const DEFAULT_DATETIME_FORMAT = 'MM/DD/YYYY';

export const LIMIT_UPLOAD_FILE_SIZE = 35 * 1024 * 1024; // 35MB

export const SUBSCRIPTION_PAGE_NAME = {
  PAID_DOCUMENT_ALMOST_DONE: 'paidDocumentAlmostReady',
};
