import { BASE_URL_V1 } from '../static/config';
import browserHistory from '../hook/history';

const TOKEN_COOKIE_NAME = 'ESIGN';
const TOKEN_TIME_LIFE_MINUTES = 30;
const COOKIE_TIME_LIFE_DAYS = 7;
let TOKEN_EXPIRES_DATE = new Date().getTime();

export function setCookie(token, type = 'user', expiresDate = null) {
  const tokenExpiresDateQuery = new Date();
  tokenExpiresDateQuery.setTime(
    tokenExpiresDateQuery.getTime() + TOKEN_TIME_LIFE_MINUTES * 60 * 1000,
  );
  TOKEN_EXPIRES_DATE = tokenExpiresDateQuery.getTime();

  const date = new Date();
  date.setTime(date.getTime() + COOKIE_TIME_LIFE_DAYS * 24 * 60 * 60 * 1000);
  const expires = `expires=${
    expiresDate ? expiresDate.toUTCString() : date.toUTCString()
  }`;

  // For admin type, store both tokens
  if (type === 'admin') {
    // Store admin token
    document.cookie = `${TOKEN_COOKIE_NAME}_admin=${token}/?${tokenExpiresDateQuery.toUTCString()};${expires};path=/`;
    // Also store it as regular user token for impersonation
    document.cookie = `${TOKEN_COOKIE_NAME}=${token}/?${tokenExpiresDateQuery.toUTCString()};${expires};path=/`;
  } else {
    // Regular user token
    document.cookie = `${TOKEN_COOKIE_NAME}=${token}/?${tokenExpiresDateQuery.toUTCString()};${expires};path=/`;
  }
}

export function deleteCookie() {
  setCookie('', '', new Date(1000));
  setCookie('', 'admin', new Date(1000));
}

export function getTokenFromCookie() {
  let token = '';

  document.cookie.split(';').forEach(cookie => {
    if (cookie.includes(`${TOKEN_COOKIE_NAME}=`)) {
      const tokenBody = cookie.split('=')[1];
      token = tokenBody.split('/?')[0];
      TOKEN_EXPIRES_DATE = Date.parse(tokenBody.split('/?')[1]);
    }
  });

  return token;
}

export function getTokenFromCookieAdmin() {
  let token = '';
  document.cookie.split(';').forEach(cookie => {
    if (cookie.includes(`${TOKEN_COOKIE_NAME}_admin=`)) {
      const tokenBody = cookie.split('=')[1];
      token = tokenBody.split('/?')[0];
      TOKEN_EXPIRES_DATE = Date.parse(tokenBody.split('/?')[1]);
    }
  });

  return token;
}

export function setAuthorization(token) {
  if (token) setCookie(token);
}

export function setAuthorizationAdmin(token) {
  if (token) setCookie(token, 'admin');
}

const createRequest = ({ method, type }) =>
  async function request({
    path,
    body,
    isMultipart,
    headers,
    serverUrl,
    signal,
  }) {
    // if ((TOKEN_EXPIRES_DATE - new Date().getTime()) < 10 * 60 * 1000 && path !== "/token-auth/") {
    //   const requestBody = {
    //     headers: {
    //       "Content-Type": "application/json; charset=utf-8",
    //       "Authorization": `Bearer ${getTokenFromCookie()}`
    //     },
    //     method: "POST",
    //     body: JSON.stringify({token: getTokenFromCookie()})
    //   };
    //
    //   await fetch(`${BASE_URL_V1}${"/token-refresh/"}`, requestBody)
    //     .then(res => res.json())
    //     .then(body => {
    //       if (body && body.token) {
    //         setCookie(body.token);
    //       }})
    //     .catch(er => {})
    // }
    const requestBody = {
      headers: {
        ...headers,
        Authorization: `Bearer ${getTokenFromCookie()}`,
      },
      credentials: 'include',
      method,
      signal,
    };
    if (body) {
      if (isMultipart) {
        requestBody.body = body;
      } else {
        requestBody.body = JSON.stringify(body);
        requestBody.headers['Content-Type'] = 'application/json';
      }
    }

    // const requestBody = {
    //   headers: {
    //     "Content-Type": "application/json; charset=utf-8",
    //     "Authorization": `Bearer ${getTokenFromCookie()}`
    //   },
    //   method
    // };

    // if (body) {
    //   requestBody.body = JSON.stringify(body);
    // }

    return fetch(`${serverUrl ? serverUrl : BASE_URL_V1}${path}`, requestBody)
      .then(async response => {
        if (response.ok) {
          return response.json();
        } else if (response.status === 401) {
          const responseDto = await response.json();
          if (responseDto.message === 'Unauthorized') {
            // alert(`please login in the system`);
            deleteCookie();
            const excludeUrls = [
              '/login',
              '/pricing',
              '/document-processing',
              '/template-form',
              '/prepare-form',
              '/sign/v2',
              '/template/v2',
              '/after-signing',
              '/after-created-template',
              '/license-invite',
              '/url/sign',
            ];
            if (
              !excludeUrls.includes(window.location.pathname) &&
              !window.location.pathname.includes('/shared-link') &&
              !window.location.pathname.includes('/preview') &&
              !path.includes('/contact') &&
              !path.includes('/forms')
            ) {
              browserHistory.go('/login', {
                prevPath: window.location.pathname,
              });
            }

            // window.location.reload();
          } else {
            return responseDto;
          }
        } else if (response.status === 403) {
          // NotificationManager.error(
          //   "You don't have a right permission to do this", '', 3000, null, null, 'filled'
          // );
          return response.json();
        } else {
          // alert(`Error ${response.status}: ${response.statusText}`);
          return response.json();
        }
      })
      .catch(err => {});
  };

export const api = {
  post: createRequest({ method: 'POST' }),
  get: createRequest({ method: 'GET' }),
  put: createRequest({ method: 'PUT' }),
  delete: createRequest({ method: 'DELETE' }),
};

export const host = {
  get: createRequest({ method: 'GET', type: 'HOST' }),
};
