/* AUTH */
export const LOGIN_USER = 'LOGIN_USER';
export const SET_LOADING = 'SET_LOADING';
export const SET_IS_2FA_MODAL_OPEN = 'SET_IS_2FA_MODAL_OPEN';
export const LOGIN_USER_2FA = 'LOGIN_USER_2FA';
export const LOGIN_USER_EXTERNALLY = 'LOGIN_USER_EXTERNALLY';
export const REFRESH_USER_DATA = 'REFRESH_USER_DATA';
export const LOGIN_USER_SUCCESS = 'LOGIN_USER_SUCCESS';
export const LOGIN_USER_ERROR = 'LOGIN_USER_ERROR';
export const REGISTER_USER = 'REGISTER_USER';
export const REGISTER_USER_SUCCESS = 'REGISTER_USER_SUCCESS';
export const LOGOUT_USER = 'LOGOUT_USER';
export const GET_CURRENT_USER = 'GET_CURRENT_USER';
export const GET_CURRENT_USER_SUCCESS = 'GET_CURRENT_USER_SUCCESS';
export const GET_STORAGE_REMAINING = 'GET_STORAGE_REMAINING';
export const STORAGE_REMAINING_SUCCESS = 'STORAGE_REMAINING_SUCCESS';
export const SET_USER_FULL_NAME = 'SET_USER_FULL_NAME';
export const SET_USER_COMPANY_NAME = 'SET_USER_COMPANY_NAME';
export const SET_USER_JOB_TITLE = 'SET_USER_JOB_TITLE';
export const SET_USER_EMAIL = 'SET_USER_EMAIL';
export const SET_USER_AVATAR = 'SET_USER_AVATAR';
export const SET_USER_ACCOUNT_TYPE = 'SET_USER_ACCOUNT_TYPE';
export const SET_USER_ADDRESS = 'SET_USER_ADDRESS';
export const SET_USER_PHONE = 'SET_USER_PHONE';
export const SET_USER_REGIONAL_SETTINGS = 'SET_USER_REGIONAL_SETTINGS';
export const SET_USER_NOTIFICATION = 'SET_USER_NOTIFICATION';
export const SET_USER_CONTACTS_SAVE_RECEPIENTS_SETTINGS =
  'SET_USER_CONTACTS_SAVE_RECEPIENTS_SETTINGS';
export const SET_USER_CONTACTS_AUTO_FILL_SETTINGS =
  'SET_USER_CONTACTS_AUTO_FILL_SETTINGS';
export const SET_USER_SIGNING_PROCESS = 'SET_USER_SIGNING_PROCESS';
export const SET_USER_TWO_FACTOR_AUTH = 'SET_USER_TWO_FACTOR_AUTH';
export const SET_USER_TWO_FACTOR_AUTH_AUTHENTICATOR =
  'SET_USER_TWO_FACTOR_AUTH_AUTHENTICATOR';
export const DELETE_USER_TWO_FACTOR_AUTH_APP =
  'DELETE_USER_TWO_FACTOR_AUTH_APP';
export const SET_USER_TWO_FACTOR_AUTH_EMAIL = 'SET_USER_TWO_FACTOR_AUTH_EMAIL';
export const DELETE_USER_TWO_FACTOR_AUTH_EMAIL =
  'DELETE_USER_TWO_FACTOR_AUTH_EMAIL';
export const SET_SHARED_LINK = 'SET_SHARED_LINK';
export const CLEAR_SHARED_LINK = 'CLEAR_SHARED_LINK';
export const SET_CONFIRM_EMAIL_SEND = 'SET_CONFIRM_EMAIL_SEND';
export const SET_IS_EMAIL_VERIFIED = 'SET_IS_EMAIL_VERIFIED';
export const SET_SHOW_LOGIN_MODAL_OPEN = 'SET_SHOW_LOGIN_MODAL_OPEN';
export const SET_USER_TEMPLATE_TIP_SETTINGS = 'SET_USER_TEMPLATE_TIP_SETTINGS';
export const SET_USER_TEMP_EMAIL = 'SET_USER_TEMP_EMAIL';
export const SET_NO_COMPLETE_TOKEN = 'SET_NO_COMPLETE_TOKEN';

// MY DOCUMENTS
export const SET_SELECTED_FILTER = 'SET_SELECTED_FILTER';
export const GET_FOLDER_ELEMENTS = 'GET_FOLDER_ELEMENTS';
export const SET_FOLDER_ELEMENTS = 'SET_FOLDER_ELEMENTS';
export const GET_RECENT_DOCUMENTS = 'GET_RECENT_DOCUMENTS';
export const GET_RECENT_DOCUMENTS_SUCCESS = 'GET_RECENT_DOCUMENTS_SUCCESS';
export const GET_FOLDER_ELEMENTS_SUCCESS = 'GET_FOLDER_ELEMENTS_SUCCESS';
export const TRIGGER_UPDATE_WAIT_FOR_ME_DOCUMENTS =
  'TRIGGER_UPDATE_WAIT_FOR_ME_DOCUMENTS';
export const TOGGLE_DOCUMENT_CHECKBOX = 'TOGGLE_DOCUMENT_CHECKBOX';
export const TOGGLE_ALL_DOCUMENTS_CHECKBOX = 'TOGGLE_ALL_DOCUMENTS_CHECKBOX';
export const CHANGE_PERMISSION_VIA_SHARE_MODAL =
  'CHANGE_PERMISSION_VIA_SHARE_MODAL';
export const CHECK_AS_FAVORITE = 'CHECK_AS_FAVORITE';
export const CHECK_AS_UNFAVORITE = 'CHECK_AS_UNFAVORITE';

// NOTIFICATIONS
export const CLOSE_NOTIFICATIONS = 'CLOSE_NOTIFICATIONS';
export const START_SPINNER_LOADER = 'START_SPINNER_LOADER';
export const START_LOADING_NOTIFICATION = 'START_LOADING_NOTIFICATION';
export const START_SUCCESS_NOTIFICATION = 'START_SUCCESS_NOTIFICATION';
export const START_ERROR_NOTIFICATION = 'START_ERROR_NOTIFICATION';
export const SET_USER_NOTIFICATION_RECEPIENT_VIEWS_SIGN_REQUEST_SETTINGS =
  'SET_USER_NOTIFICATION_RECEPIENT_VIEWS_SIGN_REQUEST_SETTINGS';
export const SET_USER_NOTIFICATION_RECEPIENT_SIGN_A_DOCUMENT_SETTINGS =
  'SET_USER_NOTIFICATION_RECEPIENT_SIGN_A_DOCUMENT_SETTINGS';
export const SET_USER_NOTIFICATION_DOCUMENT_HAS_BEEN_SIGNED_ALL_PARTIES_SETTINGS =
  'SET_USER_NOTIFICATION_DOCUMENT_HAS_BEEN_SIGNED_ALL_PARTIES_SETTINGS';
export const SET_USER_NOTIFICATION_BROWSER_PUSH_NOTIFICATIONS_SETTINGS =
  'SET_USER_NOTIFICATION_BROWSER_PUSH_NOTIFICATIONS_SETTINGS';
export const SET_USER_NOTIFICATION_IN_APP_PUSH_NOTIFICATIONS_SETTINGS =
  'SET_USER_NOTIFICATION_IN_APP_PUSH_NOTIFICATIONS_SETTINGS';
export const SET_USER_NOTIFICATION_EMAIL_ON_INVOICE_SETTINGS =
  'SET_USER_NOTIFICATION_EMAIL_ON_INVOICE_SETTINGS';

// NAVBAR
export const TOGGLE_SIDEBAR = 'TOGGLE_SIDEBAR';
export const RESIZE_DEVICE_WIDTH = 'RESIZE_DEVICE_WIDTH';

// CREATE TEMPLATE
export const SET_FILE_ID = 'SET_FILE_ID';
export const SET_UPLOADED_FILES = 'SET_UPLOADED_FILES';
export const SET_IS_IMMEDIATE_TEMPLATE = 'SET_IS_IMMEDIATE_TEMPLATE';
export const SET_REQUEST_TYPE = 'SET_REQUEST_TYPE';
export const SET_SENDER_EMAIL = 'SET_SENDER_EMAIL';
export const SET_SENDER_FULL_NAME = 'SET_SENDER_FULL_NAME';
export const SET_SIGNERS = 'SET_SIGNERS';
export const RESTORE_SIGNERS = 'RESTORE_SIGNERS';
export const SET_IS_MULTIPLE_SIGNERS = 'SET_IS_MULTIPLE_SIGNERS';
export const SET_ARE_YOU_SIGNER = 'SET_ARE_YOU_SIGNER';
export const SET_ARE_YOU_SIGNER_ONLY = 'SET_ARE_YOU_SIGNER_ONLY';
export const SET_RECEIVE_COPY = 'SET_RECEIVE_COPY';
export const SET_VERIFICATION_CODE = 'SET_VERIFICATION_CODE';
export const SET_SHOW_TEMPLATE_TIP = 'SET_SHOW_TEMPLATE_TIP';

// PANDA DOC
export const SET_TEMPLATE_ID = 'SET_TEMPLATE_ID';
export const SET_TEMPLATE_TOKEN = 'SET_TEMPLATE_TOKEN';
export const SET_TEMPLATE_IFRAME = 'SET_TEMPLATE_IFRAME';
export const SET_USER_PROFILE_COMPLETED_STATUS =
  'SET_USER_PROFILE_COMPLETED_STATUS';
export const SET_USER_COMPLETE_TOKEN = 'SET_USER_COMPLETE_TOKEN';
export const SET_TEMPLATE_TYPE = 'SET_TEMPLATE_TYPE';

// DOCUMENTS CONTROLLER
export const SET_IS_DOCUMENT_CONTROLLER_OPEN =
  'SET_IS_DOCUMENT_CONTROLLER_OPEN';
export const TOGGLE_IS_DOCUMENT_DRAGGING = 'TOGGLE_IS_DOCUMENT_DRAGGING';
export const TOGGLE_UPLOAD_DOCUMENT_MODAL = 'TOGGLE_UPLOAD_DOCUMENT_MODAL';
export const TOGGLE_CREATE_FOLDER_MODAL = 'TOGGLE_CREATE_FOLDER_MODAL';
export const TOGGLE_UPLOAD_FOLDER_MODAL = 'TOGGLE_UPLOAD_FOLDER_MODAL';
export const TOGGLE_UPLOAD_DOCUMENT_SIGN_NOTARIZE_MODAL =
  'TOGGLE_UPLOAD_DOCUMENT_SIGN_NOTARIZE_MODAL';
export const SET_SEARCH_TEXT = 'SET_SEARCH_TEXT';

// CREATE TEMPLATE V2
export const SET_WHO_NEEDS_TO_SIGN = 'SET_WHO_NEEDS_TO_SIGN';
export const SET_RECIPIENTS = 'SET_RECIPIENTS';
export const SET_EMPTY_FIELDS = 'SET_EMPTY_FIELDS';
export const RESET_TEMPLATE_STATE = 'RESET_TEMPLATE_STATE';
export const SET_ORDERED = 'SET_ORDERED';

export const TOGGLE_NEW_TEMPLATE_MODAL = 'TOGGLE_NEW_TEMPLATE_MODAL';
export const SET_DOC_TEMPLATE_TYPE = 'SET_DOC_TEMPLATE_TYPE';
export const SET_ROLES = 'SET_ROLES';

export const TOGGLE_SHOW_CONTACT_MODAL = 'TOGGLE_SHOW_CONTACT_MODAL';
export const SET_ROLE_CONTACT = 'SET_ROLE_CONTACT';
export const SET_SIGNER_CONTACT = 'SET_SIGNER_CONTACT';

export const SET_CONTACTS = 'SET_CONTACTS';
export const SET_CONTACT = 'SET_CONTACT';
export const SET_LOADING_CONTACTS = 'SET_LOADING_CONTACTS';

export const SET_PROCESS_TEMPLATE = 'SET_PROCESS_TEMPLATE';

export const SET_ACTIVE_FILEID = 'SET_ACTIVE_FILEID';
export const SET_ACTIVE_ROLE_CONTACT = 'SET_ACTIVE_ROLE_CONTACT';
export const SET_ACTIVE_SIGNER_CONTACT = 'SET_ACTIVE_SIGNER_CONTACT';
export const SET_ACTIVE_FIELDS = 'SET_ACTIVE_FIELDS';
export const SET_LOADING_TEMPLATE = 'SET_LOADING_TEMPLATE';
export const SET_SIGNATURE_REQUEST_ID_TEMPLATE =
  'SET_SIGNATURE_REQUEST_ID_TEMPLATE';

export const SET_TYPE_MAIL_BOX = 'SET_TYPE_MAIL_BOX';
export const SET_DETAIL_MAIL_BOX = 'SET_DETAIL_MAIL_BOX';
