import { api, getTokenFromCookie } from './index';
import { arrayBufferToBase64 } from '../utils';
import {
  BASE_URL_V1,
  BASE_URL_V2,
  BASE_WP_URL,
  SERVER_URL,
} from '../static/config';
import axios from 'axios';

export const uploadFiles = (file, folderId) => {
  return api.post({
    path: `/file/upload`,
    body: file,
    isMultipart: true,
  });
};

/**
 * @param {Array<{name: String, url: String}>} files
 * @param {String} [accessToken]
 * @param {('googleDrive')} [provider]
 */
export const uploadNamedFilesByUrl = (
  files,
  accessToken,
  provider,
  parentFolderId,
) => {
  return api.post({
    path: '/file/upload/bulk/named',
    body: { files, accessToken, provider, parentFolderId },
  });
};

/**
 * @param {String} url
 * @param {String} name
 * @param {('googleDrive')} [provider]
 * @param {String} [accessToken]
 */
export const uploadNamedFileByUrlPublic = (
  url,
  name,
  provider,
  accessToken,
) => {
  return api.post({
    path: '/file/upload/url/public',
    body: { url, name, accessToken, provider },
  });
};

export const uploadFileInFolder = (file, folderId) => {
  return api.post({
    path: `/file/${folderId}/upload`,
    body: file,
    isMultipart: true,
  });
};

export const checkFileExists = (name, parentFolderId) => {
  return api.post({
    path: `/file/exists`,
    body: { name, parentFolderId },
  });
};

export const immediateSignFile = (id, userDto) => {
  return api.post({
    path: `/file/${id}/notarize/sign/immediate`,
    body: userDto,
  });
};

export const mergeFilesBeforeCreateSignTemplate = filesForMerge => {
  return api.post({
    path: `/file/merge`,
    body: filesForMerge,
  });
};

export const mergeTempFilesBeforeCreateSignTemplate = fileIds => {
  return api.post({
    path: `/file/temporary/merge`,
    body: { fileIds },
  });
};

export const immediateNotarizeFile = (id, userDto) => {
  return axios
    .post(`${BASE_URL_V2}/file/${id}/notarize/immediate`, userDto, {
      headers: {
        Authorization: `Bearer ${getTokenFromCookie()}`,
      },
    })
    .then(response => response?.data)
    .catch(error => error?.response?.data);
};

export const getNotarizeLinkByFileId = fileId => {
  return api.get({
    path: `/file/${fileId}/notarize/link`,
  });
};

export const getNotarizeInfoPublic = token => {
  return api.get({
    path: `/notarize/public?token=${token}`,
  });
};

export const signFile = (id, userDto) => {
  return api.post({
    path: `/file/${id}/notarize/sign`,
    body: userDto,
  });
};

/**
 * @param {String} fileId
 * @param {String} folderId
 * @param {('keepBoth'|'replace')} [resolveMethod]
 */
export const moveFileToFolder = (fileId, folderId, resolveMethod) => {
  return api.post({
    path: `/file/${fileId}/move/${folderId}`,
    body: { resolveMethod },
  });
};

export const moveFolderToFolder = (destinationId, folderId) => {
  return api.post({
    path: `/folder/${folderId}/move`,
    body: { destinationId },
  });
};

export const changeFileTitle = (id, name) => {
  return api.put({
    path: `/file/${id}`,
    body: { name },
  });
};

export const notarizeFile = (id, userDto) => {
  return axios
    .post(`${BASE_URL_V2}/file/${id}/notarize`, userDto, {
      headers: {
        Authorization: `Bearer ${getTokenFromCookie()}`,
      },
    })
    .then(response => response?.data)
    .catch(error => error?.response?.data);
};

export const getStorageUsed = () =>
  api.get({
    path: `/file/storage`,
  });

export const getFileById = id =>
  api.get({
    path: `/file/${id}`,
  });

export const getFilePreviewById = id =>
  api.get({
    path: `/file/${id}/preview`,
  });

export const getFilePreviewBySharedLinkId = sharedLinkId =>
  api.get({
    path: `/link/shared/${sharedLinkId}/preview`,
  });

export const saveFileFromSharedLink = (id, resolveMethod) =>
  api.post({
    path: `/link/shared/${id}/save`,
    body: { resolveMethod },
  });

export const downloadFileBySharedLinkId = sharedLinkId => {
  return fetch(`${BASE_URL_V1}/link/shared/${sharedLinkId}/download`, {
    method: 'get',
    headers: new Headers({
      Authorization: `Bearer ${getTokenFromCookie()}`,
    }),
  });
};

export const getFilePreviewUrl = id =>
  api.get({
    path: `/file/${id}/url`,
  });

export const getFilePreviewUrlOriginal = id =>
  api.get({
    path: `/file/${id}/url/original`,
  });

export const getFilePreviewInfo = id =>
  api.get({
    path: `/file/${id}/preview/info`,
  });

export const getFilePublicPreviewInfo = id =>
  api.get({
    path: `/file/${id}/public-info`,
  });

export const getFileUrl = id =>
  api.get({
    path: `/file/${id}/url/sign`,
  });

export const getFileUrlPublic = id => {
  return api.get({
    path: `/file/${id}/url/public/sign`,
  });
};

export const getFileUrlPublicNoCompleteUser = (id, noCompleteToken) => {
  return api.get({
    path: `/file/${id}/url/public/sign/not-completed?token=${noCompleteToken}`,
  });
};

export const getPurchaseFileUrl = id =>
  api.get({
    path: `/file/${id}/url/purchase`,
  });

export const getPurchaseFileUrlPublic = id =>
  api.get({
    path: `/file/${id}/url/public/purchase`,
  });

export const downloadFile = id => {
  return fetch(`${BASE_URL_V1}/file/${id}/download`, {
    method: 'get',
    headers: new Headers({
      Authorization: `Bearer ${getTokenFromCookie()}`,
    }),
  });
};

// export const downloadMyltipleFile = (folderIds, fileIds) => {
//   return fetch(`${BASE_URL_V2}/download`, {
//     method: 'post',
//     body: JSON.stringify({
//       folderIds,
//       fileIds
//     }),
//     headers: new Headers({
//       'Content-Type': 'application/json',
//       Authorization: `Bearer ${getTokenFromCookie()}`,
//     }),
//   }).then(response => response?.data)
//       .catch(error => error?.response?.data)
// };

export const downloadMyltipleFile = (folderIds, fileIds) => {
  return axios
    .post(
      `${BASE_URL_V2}/download`,
      {
        folderIds,
        fileIds,
      },
      {
        headers: {
          Authorization: `Bearer ${getTokenFromCookie()}`,
        },
      },
    )
    .then(response => response?.data)
    .catch(error => error?.response?.data);
};

export const downloadExternalFile = id =>
  fetch(`${BASE_URL_V1}/file/${id}/download/external`).then(response => {
    return response;
  });

export const getRecentlyUploadedFiles = () =>
  api.get({
    path: `/file/recent`,
  });

export const trashFile = id => {
  return api.post({
    path: `/file/${id}/remove`,
  });
};

/**
 * @param {String} fileId
 * @param {{email: String, permission: ('admin'|'editor'|'viewer')}} recipient
 */
export const shareFile = (fileId, recipient) => {
  return api.post({
    path: `/file/${fileId}/share`,
    body: recipient,
  });
};

export const removeRecipientFromFile = (id, email) => {
  return api.post({
    path: `/file/${id}/share/remove`,
    body: { email },
  });
};

export const createFileHelloSignTemplate = id => {
  return api.post({
    path: `/file/${id}/sign/template`,
  });
};

export const fileHelloSignTemplateCreated = id => {
  return api.post({
    path: `/file/${id}/sign/template/completed`,
  });
};

export const signFileHelloSign = id => {
  return api.post({
    path: `/file/${id}/sign`,
  });
};

export const addMarkFavoriteFile = (type, elementId) => {
  return api.post({
    path: `/filesystem/${type}/${elementId}/favorite`,
  });
};

export const deleteMarkFavoriteFile = (type, elementId) => {
  return api.delete({
    path: `/filesystem/${type}/${elementId}/favorite`,
  });
};

export const signWithDocuSign = async (id, user) => {
  return api.get({
    serverUrl: SERVER_URL,
    path: user
      ? `/ds/sign/${id}?signerEmail=${user.email}&signerName=${user.name}`
      : `/ds/sign/${id}`,
  });
};

export const getFileImageForPreview = async id => {
  const url = `${BASE_URL_V1}/file/${id}/preview`;
  const headers = new Headers({
    Authorization: `Bearer ${getTokenFromCookie()}`,
    'Content-Type': 'multipart/form-data',
  });
  const options = {
    method: 'GET',
    headers: headers,
  };
  const request = new Request(url);
  const imgData = await fetch(request, options).then(response => {
    if (response.ok) {
      return response.arrayBuffer().then(buffer => {
        const base64Flag = 'data:image/jpeg;base64,';
        const imageStr = arrayBufferToBase64(buffer);
        const imgUrl = base64Flag + imageStr;
        return imgUrl;
      });
    } else {
      return null;
    }
  });
  return imgData;
};

//share link api
export const getShareLink = id => {
  return api.get({
    path: `/link/shared/active/${id}`,
  });
};

/**
 * @param {String} fileId
 * @param {('public'|'invited')} publicity
 */
export const createShareLink = (fileId, publicity) => {
  return api.post({
    path: `/link/shared`,
    body: { fileId, publicity },
  });
};

export const removeShareLink = id => {
  return api.delete({
    path: `/link/shared/${id}`,
  });
};

/**
 * @param {String} linkId
 * @param {('public'|'invited')} publicity
 */
export const updateShareLink = (typeFile, fileId, publicity) =>
  api.post({
    path: `/filesystem/${typeFile}/${fileId}/share/publicity`,
    body: { publicity },
  });

export const getFileContentByShareLinkId = id => {
  return api.get({
    path: `/link/shared/${id}/preview`,
  });
};

export const getFilterCount = (params = '') => {
  return axios
    .get(`${BASE_URL_V2}/file/filter/count?${params}`, {
      headers: {
        Authorization: `Bearer ${getTokenFromCookie()}`,
      },
    })
    .then(response => response?.data)
    .catch(error => error?.response?.data);
};

export const getSharedLinkInfo = linkId => {
  return api.get({
    path: `/link/shared/${linkId}/info`,
  });
};

export const getFileSharedRecipients = fileId =>
  api.get({
    path: `/filesystem/file/${fileId}/share/user`,
  });

export const findOrCreateFileSharedLink = fileId =>
  api.post({
    path: `/link/shared/active`,
    body: { fileId },
  });

export const getDownloadStatus = fileId =>
  api.get({
    path: `/download/${fileId}`,
  });

export const initExportFiles = () =>
  api.post({
    path: `/download/export`,
  });

export const getExportFiles = downloadRequestId => {
  return fetch(`${BASE_URL_V1}/download/${downloadRequestId}/download`, {
    method: 'get',
    headers: new Headers({
      Authorization: `Bearer ${getTokenFromCookie()}`,
    }),
  });
};

export const updateSharedPermissionUser = (type, fileId, email, permission) =>
  api.post({
    path: `/filesystem/${type}/${fileId}/share/permission`,
    body: {
      email,
      permission,
    },
  });

export const generateBlankDocument = () =>
  api.post({
    path: `/file/temporary/generate/blank`,
    body: {},
  });

export const getRecentActivityDocunets = limit =>
  api.get({
    path: `/file/action/recent?limit=${limit}`,
  });

export const makeCopyFile = fileId =>
  api.post({
    path: `/file/${fileId}/copy`,
    body: {
      resolveMethod: 'replace',
    },
  });

export const removeMeFromSharing = (type, fileId) =>
  api.delete({
    path: `/filesystem/${type}/${fileId}/shared`,
  });

export const removeSharing = (type, fileId) =>
  api.post({
    path: `/${type}/${fileId}/share/reset`,
    body: {},
  });

export const getWpDocuments = () => {
  return axios
    .get(`${BASE_WP_URL}/post_list`, {
      headers: {
        Authorization: `Bearer ${getTokenFromCookie()}`,
      },
    })
    .then(response => response?.data)
    .catch(error => error?.response?.data);
};

export const decryptFileProtection = (fileId, password) =>
  api.post({
    path: `/file/${fileId}/decrypt`,
    body: { password },
  });

export const decryptTemporaryFileProtection = (fileId, password) =>
  api.post({
    path: `/file/temporary/${fileId}/decrypt`,
    body: { password },
  });

export const isFileEncrypted = fileId =>
  api.get({
    path: `/file/${fileId}/encrypted`,
  });

export const isFileConverted = fileId =>
  api.get({
    path: `/file/${fileId}/is-converted`,
  });

export const isFileConvertedTemporary = fileId =>
  api.get({
    path: `/file/temporary/${fileId}/is-converted`,
  });

export const fileSystemQuery = params =>
  api.get({
    path: `/filesystem?${params}`,
  });

export const getAuditTrailByFileId = fileId => {
  return api.get({
    path: `/file/${fileId}/audit-trail`,
  });
};

// mode - BOTH or AUDIT_TRAIL
export const downloadFileWithAuditTrail = (fileId, mode) => {
  return fetch(
    `${BASE_URL_V1}/file/${fileId}/download/audit-trail?mode=${mode}`,
    {
      method: 'get',
      headers: new Headers({
        Authorization: `Bearer ${getTokenFromCookie()}`,
      }),
    },
  );
  // return api.get({
  //   path: `/file/${fileId}/download/audit-trail?mode=${mode}`,
  // });
};
